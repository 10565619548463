import React from "react";
import ServicesCard from "./ServicesCard/ServicesCard";
import stack1 from './images/stack1.png';
import stack2 from './images/stack2.png';
import stack3 from './images/stack3.png';
import stack4 from './images/stack4.png';
import stack5 from './images/stack5.png';
import stack6 from './images/stack6.png';
import stack7 from './images/stack7.png';
import stack8 from './images/stack8.png';
import stack9 from './images/stack9.png';
import stack10 from './images/stack10.png';
import stack11 from './images/stack11.png';
import stack12 from './images/stack12.png';
import stack13 from './images/stack13.png';
import stack14 from './images/stack14.png';
import stack15 from './images/stack15.png';
import stack16 from './images/stack16.png';
import './MediaServicesComponent.css';
import './ServicesComponent.css';

const ServicesComponent = () => {

    const servicesArr = [
        "Racks",
        "Repairs",
        "General Welding",
        "Valve Racks",
        "Metal Shelving",
        "Cantilever Racks",
        "Mezzanines",
        "Dock Levels",
        "Wire Partition",
        "Barriers",
        "Modular Rooms",
        "Cranes",
        "Dock Seals"
    ]

    const picArr = [
        stack8,
        stack9,
        stack10,
        stack11,
        stack12,
        stack13,
        stack14,
        stack15,
        stack16,
        stack1,
        stack2,
        stack3,
        stack4,
        stack5,
        stack6,
        stack7,
    ]
    console.log('ARRA', picArr.reverse)
    return(
        <div className="container" id="srvcs-cont" >
            <h2 id="srvcs-cont-ttl" > Services & Products </h2>
            <div className="container" id='srvcs-cont-cat-sec'>
                {
                    servicesArr.map((item) => (
                        <p className='services-product' key={item}> { item } </p>
                    ))
                }
            </div>
            <div className="container" id='srvcs-cont-sec-one'>
                    {
                        picArr.map((item) =>(
                            <ServicesCard key={`${item}`} item={item} />
                        ))
                    }
            </div>
        </div>
    )
}

export default ServicesComponent;