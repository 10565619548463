import React from "react";
import LFMxsmall from './images/LFMxsmall.png';
import blueStripes2 from './images/blueStripes2.png';
import './Header.css';
import './MediaHeader.css';

const Header = () => {
    return(
        <div className='container' id='hdr-cont'>
            <div className="container" id="hdr-cont-image-sec">
                <img id="hdr-cont-lgo" src={LFMxsmall} alt='LFM logo' height='200'/>
                <div id="hdr-cont-image-two">
                    <img id="hdr-cont-image-one" src={blueStripes2} alt="Blue Background" />
                </div>
            </div>
        </div>
    )
}

export default Header;