import React from "react";
import trustIcon from './image/trustIcon.png';
import planIcon from './image/planIcon.png';
import toolboxIcon from './image/toolboxIcon.png';
import './MainComponent.css';
import './MediaMainComponent.css';

const MainComponent = () => {
    return(
        <div className="container" id='main-cont' >
            <p id="main-cont-ntr-sec" >
                
            </p>
            <div className="container" id="main-cont-column-sec" >
                <div className="main-cont-column-set" id="mn-cont-column-1" />
                <div className="main-cont-column-set" id="mn-cont-column-2" />
                <div className="main-cont-column-set" id="mn-cont-column-3" />
                <div className="main-cont-column-set" id="mn-cont-column-4" />
                <div className="main-cont-column-set" id="mn-cont-column-5" />
            </div>
            <div className="container" id="main-cont-info-sec">
                <div className="main-cont-info-prt" id="info-sec-prt1" >
                    <img className="info-prt-image" id="main-cont-image-thr" src={trustIcon} alt="Trust Icon" />
                    <div>
                        <h2 className="info-prt-title" >
                            Trust
                        </h2>
                        <p className="info-prt-dscrptn" >
                            We build trust through our business and its services. We treat our customers like family, with kindess and respect.
                            We help each other grow through our services. 
                        </p>
                    </div>
                </div>
                <div className="main-cont-info-prt" id="info-sec-prt2" >
                    <img className="info-prt-image" id="main-cont-image-thr" src={planIcon} alt="Plan Icon" />
                    <div>
                        <h2 className="info-prt-title" >
                            Plan
                        </h2>
                        <p className="info-prt-dscrptn" >
                            We always make sure to meet your expectations. Therefore, we meet and plan to talk about what kind of service is best for you.
                            We make sure to take accurate details and suggest what is best for your needs.
                        </p>
                    </div>
                </div>
                <div className="main-cont-info-prt" id="info-sec-prt3" >
                    <img className="info-prt-image" id="main-cont-image-thr" src={toolboxIcon} alt="Plan Icon" />
                    <div>
                        <h2 className="info-prt-title" >
                            Work
                        </h2>
                        <p className="info-prt-dscrptn" >
                            We work hard to meet your deadlines. We provide quality work, service and materials to ensure our products fit your needs.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainComponent;