import React from "react";
import construction from '../NotFoundPage/images/construction.png';

const AboutComponent = () => {
    return(
        <div>
            <h2> Established in 2016 </h2>
            <img src={construction} alt="Construction Logo" />
            <h3> Sorry this page is currently under construction. </h3>
            {/* <p>
            Family owned and operated since 2016. We offer quality products, 
                provide design-build capabilities and various services. Honesty 
                is our policy. We help each other grow through our business.
            </p>
            <p> 
                We strive to help companies grow with our quality products and service.
            </p> */}
        </div>
    )
}

export default AboutComponent;