import React from "react";
import './ServicesCard.css';
import './MediaServicesCard.css';

const ServicesCard = ({ item }) => {
    console.log('IMAGE', item)
    return(
        <div className="container" id="services-card-cont" style={{backgroundImage: `url(${item})`}}>
        </div>
    )
}

export default ServicesCard;