import React from "react";
import './NavBar.css';

const NavBar = ({ setCurrentTab }) => {

    const onHandleClick = (value) => {
        setCurrentTab(value)
    }
    return(
        <div className="container" id="nv-br-cont">
            <div className="nv-lnk" onClick={(e) => onHandleClick('home')}>
                Home
            </div>
            <div className="nv-lnk" onClick={(e) => onHandleClick('services')}>
                Services
            </div>
            <div className="nv-lnk" onClick={(e) => onHandleClick('about')}>
                About
            </div>
        </div>
    )
}

export default NavBar;