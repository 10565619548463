import React, { useState } from "react";
import emailIcon from './images/email.png';
import phoneIcon from './images/phone.png';
import './Footer.css';
import './MediaFooter.css';

const Footer = () => {

    const [emailCopied, setEmailCopied] = useState('');
    let tempEmail = 'rackwarehouse.lfm@gmail.com'

    const handleCopyEmail = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(tempEmail)
        setEmailCopied(tempEmail)
    }

    return(
        <div className="container" id="ftr-cont" >
            <div className="container" id="cntct-info-sec">
                <div className="container" id="cntct-info-sec-one">
                    <h2> Contact Us </h2>
                    <div className="container" id="cntc-info-sec-one-info">
                        <div className="container cntc-info-sec-one-description" >
                            <img className="cntct-icon" src={emailIcon} alt="Email Icon"/>
                            <p className="cntct-info-sec" id="contact-email" onClick={ (e) => handleCopyEmail(e)}>
                                {tempEmail}
                                <span id='tooltip-email'>
                                    {emailCopied === tempEmail? "Email Copied" : "Copy Email"}
                                </span>
                            </p>
                        </div>
                        <div className="container cntc-info-sec-one-description" >
                            <img className="cntct-icon" src={phoneIcon} alt="Phone Icon"/>
                            <p className="cntct-info-sec"> 
                                <a id='phn-lnk' href="tel:832-633-4567">832-633-4567</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p id='cprght'>
                <strong> Copyright © All rights reserved. LFM Racks Supply | Designed & Built by Unravel </strong>
            </p>
        </div>
    )
}

export default Footer;