import { useState } from 'react';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import MainComponent from './Components/MainComponent/MainComponent';
import ServicesComponent from './Components/ServicesPage/ServicesComponent';
import AboutComponent from './Components/AboutPage/AboutCont';
import NavBar from './Components/NavBar/NavBar';
import './App.css';

function App() {

  const [currentTab, setCurrentTab] = useState('home')

  return (
    <div className="App">
      <Header/>
      <NavBar setCurrentTab={setCurrentTab}/>
      {[currentTab === 'home' ? 
        <MainComponent /> :
        currentTab === 'about' ? 
        <AboutComponent /> :
        <ServicesComponent />
      ]}
      <div id='nv-line' />
      <Footer />
      <div id='nv-line' />
    </div>
  );
}

export default App;
